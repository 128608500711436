<template>
  <div class="login-box">
    <img src="@/assets/images/login.jpg" class="login-box-bg">
    <img src="@/assets/images/login-bg1.png" class="login-box-bg1">
    <div class="login-box-main">
      <el-form :model="loginForm" :rules="loginRules" ref="loginForm" class="login-form">
        <div class="login-form-title">
          <img src="@/assets/images/logo.png">
          <span>华创商贸管理系统-询单管理</span>
        </div>

        <el-form-item prop="userName">
          <el-input v-model="loginForm.userName" placeholder="请输入您的账号">
            <template slot="prefix">
              <svg-icon icon-class="user"/>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item prop="passWord">
          <el-input v-model="loginForm.passWord" :type="passWordType?'password':'text'" placeholder="请输入您的密码">
            <template slot="prefix">
              <svg-icon icon-class="password"/>
            </template>
            <template slot="suffix">
              <svg-icon :icon-class="passWordType?'eye':'eye-open'" @click="passWordShow"/>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item class="login-form-submit">
          <el-button type="danger" @click="submitForm('loginForm')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="position: fixed; bottom: 0; line-height: 25px; background: #1d1d1f; text-align: center; width: 100%; font-size: 12px; color: #d2d2d7">
      <el-link href="https://beian.miit.gov.cn" target="_blank" style="color: #d2d2d7; font-size: 12px" type="info">闽ICP备16025860号-2</el-link>
      华创信息科技有限公司
    </div>
  </div>
</template>

<script>
import {login} from '@/api/module/common'
export default {
  components: {},
  props: [],
  data() {
    return {
      //密码是否显示
      passWordType: true,
      //提交数据
      loginForm: {
        userName: '',
        passWord: ''
      },
      //验证
      loginRules: {
        userName: [{required: true, trigger: 'blur', message: '请输入用户名',}],
        passWord: [
          {required: true, trigger: 'blur', message: '请输入密码'},
          {min: 6, max: 12, trigger: 'blur', message: '长度在 6 到 15 个字符'}
        ]
      },
    };
  },
  methods: {
    //密码是否显示
    passWordShow() {
      this.passWordType = !this.passWordType;
    },
    //登录
    submitForm(loginForm) {
      this.$refs[loginForm].validate((valid) => {
        if (valid) {
          let data={
            account:this.loginForm.userName,
            password:this.loginForm.passWord
          }
          login(data).then((result) => {
            const res=result.data;
            console.log(res)
            //存起来token
            window.sessionStorage.setItem('access_token', res.token.access_token);
            window.sessionStorage.setItem('account',res.user.account);
            window.sessionStorage.setItem('username',res.user.username);// 用户名
            window.sessionStorage.setItem('avatar',res.user.avatar);
            window.sessionStorage.setItem('id',res.user.id);
            let timestamp = Date.parse(new Date());
            window.sessionStorage.setItem('token_time',timestamp);
            window.sessionStorage.setItem('manager',res.user.manager);//是否是管理0否1是
            //当前用户权限1销售端，2采购端 3成本中心
            window.localStorage.setItem('currentPermissions',res.user.user_type);
            if(res.user.user_type==3){
              //当前页面权限
              let menu_ids=res.user.menu_ids.split(',');
              window.localStorage.setItem('routerRole',menu_ids);
              this.$store.state.routerRole=menu_ids;
            }
            this.$router.push('index/messageIndex');
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  mounted() {

  }

};
</script>
<style lang="scss">
.login-form {
  //账号密码输入框
  .el-input__inner {
    color: #1f1f1f;
    padding: 8px 35px;
    font-size: 14px;
    border: 0;
    border-radius: 18px;
    height: 42px;
    line-height: 42px;
    background: #cdf1eb;
  }

  .el-input--small {
    color: #454545;
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 18px;
  }

  .el-input__prefix {
    line-height: 42px;
    padding-left: 8px;
  }

  .el-input__suffix {
    line-height: 42px;
    padding-right: 8px;
    font-size: 20px;
    cursor: pointer;
  }

  .el-form-item {
    margin-bottom: 28px;
  }

  //登录按钮
  .login-form-submit {
    margin-bottom: 8px;

    .el-button {
      margin-top: 8px;
      width: 100%;
      border: 0;
      padding: 10px 0;
      font-size: 16px;
      border-radius: 20px;
      background: #e60012;
    }
  }
}
</style>
<style lang="scss" scoped>
.login-box {
  text-align: center;
  //水平居中
  &-main {
    width: 472px;
    height: 290px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &-bg1 {
    width: 822px;
    //height: 435px;
    //width: 968px;
    //height: 533px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  //背景图
  &-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 0;
  }

}

//表单内容布局
.login-form {
  padding: 20px 26px;
  background: #fff;
  position: relative;
  z-index: 1;
  border-radius: 15px;

  &-title {
    @include flex(row, nowrap, flex-end, center);
    padding-bottom: 32px;
    line-height: 42px;
    font: {
      weight: bold;
      size: 22px;
    };

    img {
      margin-right: 14px;
      height: 42px;
    }
  }
}


</style>
